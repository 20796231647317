import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link } from "@quarkly/widgets";
const defaultProps = {
	"href": "#",
	"text-decoration-line": "initial",
	"color": "--light",
	"font": "--lead",
	"hover-opacity": "0.9",
	"background": "#04adb5",
	"border-radius": "8px",
	"padding": "11px 24px 12px 24px",
	"children": "Maak een afspraak"
};
const overrides = {};

const MaakAfspraak = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Link {...rest}>
		{children}
	</Link>;
};

Object.assign(MaakAfspraak, { ...Link,
	defaultProps,
	overrides
});
export default MaakAfspraak;