import React from "react";
import { useOverrides } from "@quarkly/components";
import { Button } from "@quarkly/widgets";
const defaultProps = {
	"lg-margin": "20px 0px 20px 0px",
	"lg-background": "--color-secondary",
	"href": "/aanbod",
	"type": "link",
	"lg-text-decoration-line": "initial",
	"background": "--color-secondary",
	"text-decoration-line": "initial",
	"margin": "20px 0px 20px 0px"
};
const overrides = {};

const TerugNaarAanbod = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Button {...rest}>
		Terug naar aanbod
		{children}
	</Button>;
};

Object.assign(TerugNaarAanbod, { ...Button,
	defaultProps,
	overrides
});
export default TerugNaarAanbod;