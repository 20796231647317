import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Span, Strong, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"sm-padding": "60px 0 60px 0",
	"padding": "40px 0 40px 0",
	"background": "--color-primary",
	"margin": "20px 0px 20px 0px"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline2",
			"text-align": "center",
			"children": "Troeven"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "24px 4%",
			"md-grid-template-columns": "1fr",
			"margin": "15px 0px 0px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"justify-content": "flex-start",
			"flex-direction": "column",
			"display": "flex"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 18px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"text-align": "center",
			"children": "Snel, betaalbaar en wendbaar"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"flex": "1 0 auto",
			"text-align": "center",
			"children": <>
				dimple gebruikt{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						state-of-the-art low-code
					</Span>
					{" "}platformen
				</Strong>
				{" "}(Notion, Make, Tally, ...).  Hierdoor zijn we bijzonder snel, kosteneffectief en flexibel.{"  "}
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"justify-content": "flex-start",
			"flex-direction": "column",
			"display": "flex"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 18px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"text-align": "center",
			"children": "Maatwerk"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"flex": "1 0 auto",
			"text-align": "center",
			"children": <>
				dimple ontwikkelt digitale werkplekken{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					afgestemd op jouw doelen, noden en werkcontext
				</Strong>
				{" "}(en niet omgekeerd!).   Een digitale werkplek is gemaakt om mee te groeien met jouw zaak.  Met elke nieuwe uitdaging en behoefte die zich aandient.{"        "}
			</>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"justify-content": "flex-start",
			"flex-direction": "column",
			"display": "flex"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 18px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"text-align": "center",
			"children": "Gebruiksvriendelijk"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"flex": "1 0 auto",
			"text-align": "center",
			"children": <>
				Een digitale werkplek is géén doel op zich.   Het is{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					een middel
				</Strong>
				{" "}om mensen te helpen om hun doelen te bereiken. dimple heeft ruime ervaring met het ontwerp en de bouw van gebruikersvriendelijke digitale webapplicaties binnen een professionele context.
			</>
		}
	}
};

const Troeven = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Troeven, { ...Section,
	defaultProps,
	overrides
});
export default Troeven;