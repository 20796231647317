import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-light",
	"padding": "0px 0 0px 0",
	"sm-padding": "40px 0"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"as": "h2",
			"font": "--headline2",
			"md-font": "--headline2",
			"margin": "20px 0 0 0",
			"children": "Contact"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "20px 0 20px 0",
			"text-align": "center",
			"children": <>
				dirk@dimple.be
				<br />
				+32 472 61 99 33
				<br />
				{"\n"}btw: BE 0870.059.613{"\n\n"}
				<br />
				{"\n"}iban: BE59 7340 1327 1326
				<br />
				{"\n"}bic: KREDBEBB{"\n\n\n\n"}
				<br />
				<Link href="https://www.linkedin.com/in/dirkdiddens/" color="--primary" text-decoration-line="initial">
					LinkedIn
				</Link>
			</>
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"font": "--lead",
			"margin": "20px",
			"background": "--color-primary",
			"color": "--light",
			"type": "link",
			"text-decoration-line": "initial",
			"href": "mailto:dirk@dimple.be"
		}
	}
};

const Contact2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Button {...override("button")}>
			Maak een afspraak
		</Button>
		{children}
	</Section>;
};

Object.assign(Contact2, { ...Section,
	defaultProps,
	overrides
});
export default Contact2;