import React from "react";
import { useOverrides, Override, Menu } from "@quarkly/components";
import { Image, LinkBox, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
const defaultProps = {};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "12px 0",
			"justify-content": "space-between",
			"align-items": "center",
			"flex-direction": "row",
			"md-flex-direction": "column"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "/index",
			"display": "block",
			"width": "250px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"display": "block",
			"width": "auto",
			"height": "auto",
			"src": "https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple%20logo%20-%20logo%20+%20tagline.png?v=2023-06-07T12:24:58.155Z",
			"max-width": "80%"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"onloadShow": false
		}
	},
	"menu": {
		"kind": "Menu",
		"props": {
			"display": "flex",
			"justify-content": "center",
			"font": "--lead",
			"font-weight": "700",
			"md-flex-direction": "column",
			"md-align-items": "center"
		}
	},
	"menuOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"text-decoration": "none",
			"color": "--dark",
			"padding": "6px 12px"
		}
	},
	"menuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"color": "--primary"
		}
	},
	"menuOverride2": {
		"kind": "Override",
		"props": {
			"slot": "item",
			"padding": "6px"
		}
	}
};

const MenuHam = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Image {...override("image")} />
			</LinkBox>
			<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
				<Menu {...override("menu")}>
					<Override {...override("menuOverride")} />
					<Override {...override("menuOverride1")} />
					<Override {...override("menuOverride2")} />
				</Menu>
			</QuarklycommunityKitMobileSidePanel>
		</Box>
		{children}
	</Section>;
};

Object.assign(MenuHam, { ...Section,
	defaultProps,
	overrides
});
export default MenuHam;